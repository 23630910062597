import { useEffect,useState } from 'react';
import { Image ,Input,Divider,Select,Space,Slider,Switch,Segmented,Button,Tooltip} from 'antd';
import { FileImageOutlined, LoadingOutlined ,InfoCircleOutlined} from "@ant-design/icons"
import ImageComponent from "../../../component/ImageComponent"
import { initBlockParams } from "../tools/initBlockParams"



import emoji_arrow_up from "../static/emoji_arrow_up.png";
import emoji_arrow_down from "../static/emoji_arrow_down.png";
import emoji_arrow_repeat from "../static/emoji_arrow_repeat.png";
import emoji_spark from "../static/emoji_spark.png";
import emoji_kiui from "../static/emoji_kiui.png";


import emoji_scorpio from "../static/emoji_scorpio.png";
import emoji_quest_red from "../static/emoji_quest_red.png";
import emoji_quest_white from "../static/emoji_quest_white.png";
import emoji_info from "../static/emoji_info.png";
import emoji_capricorn from "../static/emoji_capricorn.png";

import emoji_stop from "../static/emoji_stop.png";
import emoji_atom from "../static/emoji_atom.png";
import emoji_noentry from "../static/emoji_noentry.png";
import emoji_mail from "../static/emoji_mail.png";
import emoji_cut from "../static/emoji_cut.png";
import emoji_black from "../static/emoji_black.png";
import emoji_a from "../static/emoji_a.png";
import emoji_edit from "../static/emoji_edit.png";
import emoji_package from "../static/emoji_package.png";





const conditionList = [
    {
        value: 'equal',
        label: "=",
    },
    {
        value: 'not-equal',
        label: "≠",
    },
    {
        value: 'larger',
        label: ">",
    },
    {
        value: 'smaller',
        label: "<",
    },
    {
        value: 'includes',
        label: "∋",
    },
    {
        value: 'not-includes',
        label: "∌",
    },
    
]



const optionsList = [
    {
        value: 'var-red',
        label: "🔴 変数 red",
    },
    {
        value: 'var-orange',
        label: "🟠 変数 orange",
    },
    {
        value: 'var-yellow',
        label: "🟡 変数 yellow",
    },
    {
        value: 'var-green',
        label: "🟢 変数 green",
    },
    {
        value: 'var-blue',
        label: "🔵 変数 blue",
    },

    {
        value: 'var-1',
        label: "1️⃣ 変数 1",
    },

    {
        value: 'var-2',
        label: "2️⃣ 変数 2",
    },

    {
        value: 'var-3',
        label: "3️⃣ 変数 3",
    },

    {
        value: 'var-4',
        label: "4️⃣ 変数 4",
    },

    {
        value: 'var-5',
        label: "5️⃣ 変数 5",
    },

    {
        value: 'var-6',
        label: "6️⃣ 変数 6",
    },

    {
        value: 'var-7',
        label: "7️⃣ 変数 7",
    },

    {
        value: 'var-8',
        label: "8️⃣ 変数 8",
    },
    {
        value: 'var-9',
        label: "9️⃣ 変数 9",
    },
    {
        value: 'var-10',
        label: "🔟 変数 10",
    },
]

const { TextArea } = Input;
const marks = {
    0: '0',
    0.2: '0.2',
    1: {
      style: {
        color: '#f50',
      },
      label: <strong>1</strong>,
    },
  };


const getIfStartFormConditionMaxNum = (params) =>{
    let maxNum = 1
    if(params.ifCondition2){maxNum = 2}
    if(params.ifCondition3){maxNum = 3}
    if(params.ifCondition4){maxNum = 4}
    if(params.ifCondition5){maxNum = 5}
    return(maxNum)
}


const getIfStartForm = (num,params,onChangeParams,widthOfChild,isEditing) =>{

    let maxNum = getIfStartFormConditionMaxNum(params)

    const ifCondition = "ifCondition"+num
    const source = "source"+num
    const type = "type"+num
    const value = "value"+num

    return(
        <div style={{marginBottom:20}}>
            <Space>
                <Select
                    onChange={(value)=>{onChangeParams(source,value)}} 
                    disabled={!isEditing}
                    defaultValue={params[source]}
                    value={params[source]}
                    style={{
                        width: widthOfChild-100 - 150,
                    }}
                    // allowClear
                    options={optionsList}
                    placeholder="select it"
                />
                <Select
                    onChange={(value)=>{onChangeParams(type,value)}} 
                    disabled={!isEditing}
                    defaultValue={params[type]}
                    value={params[type]}
                    style={{
                        width: 150,
                    }}
                    // allowClear
                    options={conditionList}
                    placeholder="select it"
                />
                
            </Space>
            <div style={{height:10}}/>
            <Input placeholder="value" style={{width:"100%"}} value={params[value]} onChange={(e)=>{onChangeParams(value,e.target.value)}} />
            <div style={{height:10}}/>
            {num == maxNum && (
                <Space style={{position:"relative",width:widthOfChild-90}}>
                    {maxNum < 5 && (
                        <Button onClick={()=>{
                            onChangeParams("ifCondition"+(num + 1),1)
                        }} style={{}}
                        >+</Button>
                    )}
                    
                    {num > 1 && (
                        <Button danger style={{}}
                        onClick={()=>{
                            onChangeParams("ifCondition"+(num),0)
                        }}
                        >-</Button>
                    )}
                    {num > 1 && (
                        <Select
                            style={{position:"absolute",right:0,top:0,width: 100}}
                            onChange={(value)=>{onChangeParams("gate",value)}} 
                            disabled={!isEditing}
                            // defaultValue={"params.model"}
                            value={params.gate}
                            // allowClear
                            options={[
                                {
                                    value: 'AND',
                                    label: 'AND',
                                },
                                {
                                    value: 'OR',
                                    label: 'OR',
                                },
                            ]}
                            placeholder="select it"
                        />
                    )}
                </Space>
            )}
            
            
        </div>
        
    )
}


const BlockContent = (props) => {
    

    // const params = props.params
    const type = props.type

    
    const isEditing = props.isEditing
    const widthOfChild = props.widthOfChild
    const ifWithTab = props.ifWithTab
    const contentid = props.contentid

    

    // console.log(ifWithTab)
    useEffect(() => {
        console.log("refreshing.....------")

        // 对于参数不全的params进行初始化
        const conpleteParams = initBlockParams(type,props.params)
        props.onChangeParams(conpleteParams)
        setParams(conpleteParams)

      }, [props.isEditing,props.type]
    )

    
    const [params,setParams] = useState(props.params)
    
    const onChangeParams = (section,value) => {

        console.log(value)

        let newParams = { ...params };
        newParams[section] = value
        setParams(newParams)
        console.log(newParams)

        props.onChangeParams(newParams)

    }


    const TabDiv = () => {
        return(
            <div style={{
                width:42,height:70,
                // backgroundColor: '#ff0000',
                display: "flex",
                justifyContent: "center",
                alignItems: "center"   
                
                }}>
                <div style={{width:10,height:10,backgroundColor:"#d8d8d8",borderRadius:5}}></div>
            </div>
        )
    }

    const renderContent = () => {
        switch (type) {
            case 'llm':

                //-----------------------------------------------
                // pinLLM
                return (

                    <Space>
                        {ifWithTab && (
                            <TabDiv/>
                        )}
                        <div style={{borderRadius:10,
                            padding:10,
                            backgroundColor: '#f0f0f0',
                            // outline:  isEditing && '1px solid #ccc',
                            height:isEditing ? (params.prompt == "$INHERT" ? 365:475):60,
                            overflow:"hidden",
                            transition: 'height 0.5s ease',
                            // marginLeft:ifWithTab && 50


                            }}>
                            <div style={{float:"left",width:60,height:60,overflow:"hidden"}}>
                                <ImageComponent 
                                    src = {emoji_spark}
                                    width = {60} 
                                    height = {60} 
                                />
                            </div>
                            <div style={{float:"left",width:ifWithTab ? (widthOfChild - 90 - 50):(widthOfChild - 90),marginLeft:10,}}>
                                <div style={{fontSize:18,fontWeight:"bold",marginTop:!isEditing && 5}}>LLMブロック</div>
                                {isEditing ? (
                                    <Input value={params.title} onChange={(e)=>{onChangeParams("title",e.target.value)}} />
                                ):(
                                    <div style={{color:"#a8a8a8",fontSize:15,marginTop:5}}>{params.title}</div>
                                )}

                                <div style={{width:"100%",marginTop:-5,marginBottom:-20}}><Divider/></div>
                                
                                <div style={{height:10}}/>
                                <div style={{fontSize:15,fontWeight:"bold",position:"relative"}}>
                                    <Space>{"With context?  "}
                                        <Tooltip title="text....">
                                            <InfoCircleOutlined style={{color:"#a8a8a8"}}/>
                                        </Tooltip>
                                        
                                    </Space>
                                    
                                    <Switch style={{position:"absolute",right:0,top:0}}
                                        defaultChecked={params.withContext == 1} 
                                        disabled={!isEditing}
                                        onChange={()=>{onChangeParams("withContext",params.withContext == 1 ? 0:1)}}
                                    />
                                </div>
                                {/* <Segmented options={["❌ No",'✅ Yes']}  block /> */}
                                
                                
                                <div style={{width:"100%",marginTop:-5,marginBottom:-20}}><Divider/></div>
                                <div style={{height:10}}/>
                                <div style={{fontSize:15,fontWeight:"bold"}}>Model</div>
                                <div style={{height:10}}/>
                                <Select
                                    onChange={(value)=>{onChangeParams("model",value)}} 
                                    disabled={!isEditing}
                                    // defaultValue={"params.model"}
                                    value={params.model}
                                    style={{
                                        width: 200,
                                    }}
                                    // allowClear
                                    options={[
                                        {
                                            value: 'openai-gpt-3.5',
                                            label: '🍀 openai-gpt-3.5',
                                        },
                                        {
                                            value: 'openai-gpt-4',
                                            label: '🍀 openai-gpt-4',
                                        },
                                        {
                                            value: 'openai-gpt-4o',
                                            label: '🍀 openai-gpt-4o',
                                        },
                                        {
                                            value: 'deepseek-chat',
                                            label: '🐬 deepseek-chat',
                                        },
                                    ]}
                                    placeholder="select it"
                                />

                                <div style={{height:10}}/>
                                <div style={{fontSize:15,fontWeight:"bold"}}>Temperature</div>
                                <div style={{height:10}}/>
                                <Slider 
                                    disabled={!isEditing} 
                                    marks={marks} 
                                    defaultValue={params.temperature} 
                                    value={params.temperature} 
                                    min={0} max={1} step={0.1}
                                    onChange={(value)=>{onChangeParams("temperature",value)}}
                                    
                                    />

                                <div style={{height:10}}/>
                                <div style={{fontSize:15,fontWeight:"bold"}}>Prompt</div>

                                <div style={{height:10}}/>
                                <Segmented options={["継承","固定"]}  
                                    block 
                                    defaultValue = {params.prompt == "$INHERT" ? "継承":"固定"}
                                    onChange={(value)=>{
                                        console.log(value)
                                        if(value=="継承" && params.prompt != "$INHERT"){
                                            onChangeParams("prompt","$INHERT")
                                        }
                                        if(value=="固定" && params.prompt == "$INHERT"){
                                            onChangeParams("prompt","")
                                        }
                                    
                                    }}
                                />

                                {params.prompt != "$INHERT" && (
                                    <div>
                                        <div style={{height:10}}/>
                                       
                                        <TextArea
                                            disabled={!isEditing}
                                            value={params.prompt}
                                            onChange={(e)=>{onChangeParams("prompt",e.target.value)}} 
                                            placeholder="prompt here"
                                            autoSize={{
                                            minRows: 4,
                                            maxRows: 4,
                                            }}
                                        />
                                    </div>
                                )}
                                

                                
                            </div>
                        </div>
                    </Space>
                )

            //-----------------------------------------------
            // pinXXXX
            case 'var-read':
                return (
                    <Space>
                        {ifWithTab && (
                            <TabDiv/>
                        )}
                        <div style={{borderRadius:10,
                            padding:10,
                            backgroundColor: '#f0f0f0',
                            // outline:  isEditing && '1px solid #ccc',
                            height:isEditing ? 155:60,
                            overflow:"hidden",
                            transition: 'height 0.5s ease',
                            // marginLeft:ifWithTab && 50

                            }}>
                            <div style={{float:"left",width:60,height:60,overflow:"hidden"}}>
                                <ImageComponent 
                                    src = {emoji_arrow_up}
                                    width = {60} 
                                    height = {60} 
                                />
                            </div>
                            <div style={{float:"left",width:ifWithTab ? (widthOfChild - 90 - 50):(widthOfChild - 90),marginLeft:10,}}>
                                <div style={{fontSize:18,fontWeight:"bold",marginTop:!isEditing && 5}}>変数を読み取る</div>
                                {isEditing ? (
                                    <Input value={params.title} onChange={(e)=>{onChangeParams("title",e.target.value)}} />
                                ):(
                                    <div style={{color:"#a8a8a8",fontSize:15,marginTop:5}}>{params.title}</div>
                                )}

                                <div style={{width:"100%",marginTop:-5,marginBottom:-20}}><Divider/></div>
                                <div style={{height:10}}/>
                                <div style={{fontSize:15,fontWeight:"bold"}}>Source</div>
                                <div style={{height:10}}/>
                                <Select
                                    onChange={(value)=>{onChangeParams("source",value)}} 
                                    disabled={!isEditing}
                                    defaultValue={params.source}
                                    value={params.source}
                                    style={{
                                        width: 200,
                                    }}
                                    // allowClear
                                    options={[
                                        {
                                            value: 'user-input',
                                            label: "🧑 ユーザ入力",
                                        },...optionsList]}
                                    placeholder="select it"
                                />
                                {/* <div style={{height:10}}/> */}
                                {/* <div style={{width:"100%",marginTop:-5,marginBottom:-20}}><Divider/></div>
                                <div style={{height:10}}/>
                                <div style={{fontSize:15,fontWeight:"bold"}}>前に追加</div>
                                <div style={{height:10}}/>
                                <TextArea
                                    disabled={!isEditing}
                                    value={params.addAhead}
                                    onChange={(e)=>{onChangeParams("addAhead",e.target.value)}} 
                                    placeholder="prompt here"
                                    autoSize={{
                                    minRows: 4,
                                    maxRows: 4,
                                    }}
                                />
                                <div style={{height:10}}/>
                                <div style={{fontSize:15,fontWeight:"bold"}}>後ろに追加</div>
                                <div style={{height:10}}/>
                                <TextArea
                                    disabled={!isEditing}
                                    value={params.addBehind}
                                    onChange={(e)=>{onChangeParams("addBehind",e.target.value)}} 
                                    // onChange={(e) => setValue(e.target.value)}
                                    placeholder="prompt here"
                                    autoSize={{
                                    minRows: 4,
                                    maxRows: 4,
                                    }}
                                /> */}
                                
                                
                            </div>
                        </div>
                    </Space>
                )

            //-----------------------------------------------
            // pinXXXX
            case 'var-write':
                return (
                    <Space>
                        {ifWithTab && (
                            <TabDiv/>
                        )}
                        <div style={{borderRadius:10,
                            padding:10,
                            backgroundColor: '#f0f0f0',
                            // outline:  isEditing && '1px solid #ccc',
                            height:isEditing ? 260:60,
                            overflow:"hidden",
                            transition: 'height 0.5s ease',
                            // marginLeft:ifWithTab && 50

                            }}>
                            <div style={{float:"left",width:60,height:60,overflow:"hidden"}}>
                                <ImageComponent 
                                    src = {emoji_arrow_down}
                                    width = {60} 
                                    height = {60} 
                                />
                            </div>
                            <div style={{float:"left",width:ifWithTab ? (widthOfChild - 90 - 50):(widthOfChild - 90),marginLeft:10,}}>
                                <div style={{fontSize:18,fontWeight:"bold",marginTop:!isEditing && 5}}>変数に書き込み</div>
                                {isEditing ? (
                                    <Input value={params.title} onChange={(e)=>{onChangeParams("title",e.target.value)}} />
                                ):(
                                    <div style={{color:"#a8a8a8",fontSize:15,marginTop:5}}>{params.title}</div>
                                )}
                                <div style={{height:10}}/>
                                <div style={{width:"100%",marginTop:-5,marginBottom:-20}}><Divider/></div>
                                <div style={{height:10}}/>
                                <div style={{fontSize:15,fontWeight:"bold"}}>書き込み方式</div>
                                <div style={{height:10}}/>
                                <Segmented options={["上書き","書き足し"]}  
                                    block 
                                    defaultValue = {params.ifAdd == 1 ? "書き足し":"上書き"}
                                    onChange={(value)=>{
                                        if(value=="上書き"){
                                            onChangeParams("ifAdd",0)
                                        }
                                        if(value=="書き足し"){
                                            onChangeParams("ifAdd",1)
                                        }
                                    }}
                                />
                                <div style={{width:"100%",marginTop:-5,marginBottom:-20}}><Divider/></div>
                                <div style={{height:10}}/>
                                <div style={{fontSize:15,fontWeight:"bold"}}>Target</div>
                                <div style={{height:10}}/>
                                <Select
                                    disabled={!isEditing}
                                    defaultValue={params.target}
                                    value={params.target}
                                    onChange={(value)=>{onChangeParams("target",value)}} 
                                    style={{
                                        width: 200,
                                    }}
                                    // allowClear
                                    options={optionsList}
                                    placeholder="select it"
                                />
                                
                                
                            </div>
                        </div>
                    </Space>
                )


            // pinXXXX
            case 'edit':
                return (
                    <Space>
                        {ifWithTab && (
                            <TabDiv/>
                        )}
                        <div style={{borderRadius:10,
                            padding:10,
                            backgroundColor: '#f0f0f0',
                            // outline:  isEditing && '1px solid #ccc',
                            height:isEditing ? 375:60,
                            overflow:"hidden",
                            transition: 'height 0.5s ease',
                            // marginLeft:ifWithTab && 50

                            }}>
                            <div style={{float:"left",width:60,height:60,overflow:"hidden"}}>
                                <ImageComponent 
                                    src = {emoji_edit}
                                    width = {60} 
                                    height = {60} 
                                />
                            </div>
                            <div style={{float:"left",width:ifWithTab ? (widthOfChild - 90 - 50):(widthOfChild - 90),marginLeft:10,}}>
                                <div style={{fontSize:18,fontWeight:"bold",marginTop:!isEditing && 5}}>出力を編集</div>
                                {isEditing ? (
                                <Input value={params.title} onChange={(e)=>{onChangeParams("title",e.target.value)}} />
                            ):(
                                <div style={{color:"#a8a8a8",fontSize:15,marginTop:5}}>{params.title}</div>
                            )}

                                
                                <div style={{height:10}}/>
                                <div style={{width:"100%",marginTop:-5,marginBottom:-20}}><Divider/></div>
                                <div style={{height:10}}/>
                                <div style={{fontSize:15,fontWeight:"bold"}}>前に追加</div>
                                <div style={{height:10}}/>
                                <TextArea
                                    disabled={!isEditing}
                                    value={params.addAhead}
                                    onChange={(e)=>{onChangeParams("addAhead",e.target.value)}} 
                                    placeholder="prompt here"
                                    autoSize={{
                                    minRows: 4,
                                    maxRows: 4,
                                    }}
                                />
                                <div style={{height:10}}/>
                                <div style={{fontSize:15,fontWeight:"bold"}}>後ろに追加</div>
                                <div style={{height:10}}/>
                                <TextArea
                                    disabled={!isEditing}
                                    value={params.addBehind}
                                    onChange={(e)=>{onChangeParams("addBehind",e.target.value)}} 
                                    placeholder="prompt here"
                                    autoSize={{
                                    minRows: 4,
                                    maxRows: 4,
                                    }}
                                />

                                <div style={{height:10}}/>
                                
                                
                                
                            </div>
                        </div>
                    </Space>
                )

            // pinXXXX
            case 'combine':
                return (
                    <Space>
                        {ifWithTab && (
                            <TabDiv/>
                        )}
                        <div style={{borderRadius:10,
                            padding:10,
                            backgroundColor: '#f0f0f0',
                            // outline:  isEditing && '1px solid #ccc',
                            height:isEditing ? 430:60,
                            overflow:"hidden",
                            transition: 'height 0.5s ease',
                            // marginLeft:ifWithTab && 50

                            }}>
                            <div style={{float:"left",width:60,height:60,overflow:"hidden"}}>
                                <ImageComponent 
                                    src = {emoji_package}
                                    width = {60} 
                                    height = {60} 
                                />
                            </div>
                            <div style={{float:"left",width:ifWithTab ? (widthOfChild - 90 - 50):(widthOfChild - 90),marginLeft:10,}}>
                                <div style={{fontSize:18,fontWeight:"bold",marginTop:!isEditing && 5}}>変数を結合</div>
                                {isEditing ? (
                                <Input value={params.title} onChange={(e)=>{onChangeParams("title",e.target.value)}} />
                            ):(
                                <div style={{color:"#a8a8a8",fontSize:15,marginTop:5}}>{params.title}</div>
                            )}

                                
                                <div style={{height:10}}/>
                                <div style={{width:"100%",marginTop:-5,marginBottom:-20}}><Divider/></div>

                                

                                    
                                <div style={{height:10}}/>
                                {[1,2,3,4,5,6,7,8].map((item, index) => (
                                    <div ke={index}>
                                        <Space>
                                            <div style={{fontSize:15,fontWeight:"bold",width:80}}>アイテム{" "+item}</div>
                                            <Select
                                                onChange={(value)=>{onChangeParams("item"+item,value)}} 
                                                disabled={!isEditing}
                                                defaultValue={params["item"+item]}
                                                value={params["item"+item]}
                                                style={{
                                                    width: widthOfChild - 180,
                                                }}
                                                // allowClear
                                                options={[
                                                    {
                                                        value: 'none',
                                                        label: "なし",
                                                    },
                                                    {
                                                        value: 'inhert',
                                                        label: "継承",
                                                    },
                                                    {
                                                        value: 'user-input',
                                                        label: "🧑 ユーザ入力",
                                                    },...optionsList]}
                                                placeholder="select it"
                                            />
                                        </Space>
                                        <div style={{height:10}}/>
                                    </div>
                                    ))}
                                
                                <div style={{height:10}}/>
                                
                                
                                
                                
                            </div>
                        </div>
                    </Space>
                )

            //-----------------------------------------------
            // pinReply
            case 'reply-user':
                return (
                    <Space>
                        {ifWithTab && (
                            <TabDiv/>
                        )}
                        <div style={{borderRadius:10,
                            padding:10,
                            backgroundColor: '#f0f0f0',
                            // outline:  isEditing && '1px solid #ccc',
                            height:isEditing ? 
                                60
                                :
                                60,
                            overflow:"hidden",
                            transition: 'height 0.5s ease',
                            // marginLeft:ifWithTab && 50

                            }}>
                            <div style={{float:"left",width:60,height:60,overflow:"hidden"}}>
                                <ImageComponent 
                                    src = {emoji_info}
                                    width = {60} 
                                    height = {60} 
                                />
                            </div>
                            <div style={{float:"left",width:ifWithTab ? (widthOfChild - 90 - 50):(widthOfChild - 90),marginLeft:10,}}>
                                <div style={{fontSize:18,fontWeight:"bold",marginTop:!isEditing && 5}}>ユーザに返信</div>
                                {isEditing ? (
                                <Input value={params.title} onChange={(e)=>{onChangeParams("title",e.target.value)}} />
                            ):(
                                <div style={{color:"#a8a8a8",fontSize:15,marginTop:5}}>{params.title}</div>
                            )}
                            <div style={{height:10}}/>
                            {/* <div style={{width:"100%",marginTop:-5,marginBottom:-20}}><Divider/></div>
                            <div style={{height:10}}/>
                            <div style={{fontSize:15,fontWeight:"bold"}}>内容</div>

                            <Segmented options={["継承","固定"]}  
                                block 
                                defaultValue = {params.input == "$INHERT" ? "継承":"固定"}
                                onChange={(value)=>{
                                    console.log(value)
                                    if(value=="継承" && params.input != "$INHERT"){
                                        onChangeParams("input","$INHERT")
                                    }
                                    if(value=="固定" && params.input == "$INHERT"){
                                        onChangeParams("input","")
                                    }
                                   
                                }}
                            />

                            {params.input != "$INHERT" && (
                                <div>
                                    <div style={{height:10}}/>
                                   
                                    <TextArea
                                        disabled={!isEditing}
                                        value={params.input}
                                        onChange={(e)=>{onChangeParams("input",e.target.value)}} 
                                        placeholder="prompt here"
                                        autoSize={{
                                        minRows: 4,
                                        maxRows: 4,
                                        }}
                                    />
                                </div>
                            )} */}
                            

                                
                                
                                
                            </div>
                        </div>
                    </Space>
                )

            //-----------------------------------------------
            // pinReply
            case 'extract-json':
                return (
                    <Space>
                        {ifWithTab && (
                            <TabDiv/>
                        )}
                        <div style={{borderRadius:10,
                            padding:10,
                            backgroundColor: '#f0f0f0',
                            // outline:  isEditing && '1px solid #ccc',
                            height:isEditing ? 
                                (params.input == "$INHERT" ? 160:235)
                                :
                                60,
                            overflow:"hidden",
                            transition: 'height 0.5s ease',
                            // marginLeft:ifWithTab && 50

                            }}>
                            <div style={{float:"left",width:60,height:60,overflow:"hidden"}}>
                                <ImageComponent 
                                    src = {emoji_cut}
                                    width = {60} 
                                    height = {60} 
                                />
                            </div>
                            <div style={{float:"left",width:ifWithTab ? (widthOfChild - 90 - 50):(widthOfChild - 90),marginLeft:10,}}>
                                <div style={{fontSize:18,fontWeight:"bold",marginTop:!isEditing && 5}}>Json抽出</div>
                                {isEditing ? (
                                <Input value={params.title} onChange={(e)=>{onChangeParams("title",e.target.value)}} />
                            ):(
                                <div style={{color:"#a8a8a8",fontSize:15,marginTop:5}}>{params.title}</div>
                            )}
                            <div style={{height:10}}/>
                            <div style={{width:"100%",marginTop:-5,marginBottom:-20}}><Divider/></div>
                            <div style={{height:10}}/>
                            <div style={{fontSize:15,fontWeight:"bold"}}>抽出するタグ</div>
                            <Input value={params.tag} onChange={(e)=>{onChangeParams("tag",e.target.value)}} />
                            
                            <div style={{height:20}}/>
                            <div style={{fontSize:15,fontWeight:"bold"}}>失敗した際の出力値</div>
                            <Input value={params.error_message} onChange={(e)=>{onChangeParams("error_message",e.target.value)}} />
                            

                                
                                
                                
                            </div>
                        </div>
                    </Space>
                )

            //-----------------------------------------------
            // pinIfStart
            case 'if-start':
                return (
                    <Space>
                        {ifWithTab && (
                            <TabDiv/>
                        )}
                        <div style={{borderRadius:10,
                            padding:10,
                            backgroundColor: '#f0f0f0',
                            // outline:  isEditing && '1px solid #ccc',
                            height:isEditing ? (150 + getIfStartFormConditionMaxNum(params) * 105):60,
                            overflow:"hidden",
                            transition: 'height 0.5s ease',
                            // marginLeft:ifWithTab && 50

                            }}>
                            <div style={{float:"left",width:60,height:60,overflow:"hidden"}}>
                                <ImageComponent 
                                    src = {emoji_quest_red}
                                    width = {60} 
                                    height = {60} 
                                />
                            </div>
                            <div style={{float:"left",width:ifWithTab ? (widthOfChild - 90 - 50):(widthOfChild - 90),marginLeft:10,}}>
                                <div style={{fontSize:18,fontWeight:"bold",marginTop:!isEditing && 5}}>条件開始</div>
                                {isEditing ? (
                                    <Input value={params.title} onChange={(e)=>{onChangeParams("title",e.target.value)}} />
                                ):(
                                    <div style={{color:"#a8a8a8",fontSize:15,marginTop:5}}>{params.title}</div>
                                )}
                            <div style={{height:10}}/>
                            <div style={{width:"100%",marginTop:-5,marginBottom:-20}}><Divider/></div>
                            <div style={{height:10}}/>
                            <div style={{fontSize:15,fontWeight:"bold"}}>条件</div>
                            <div style={{height:10}}/>
                            {true && (getIfStartForm(1,params,onChangeParams,widthOfChild,isEditing))}
                            {params.ifCondition2 == 1 && (getIfStartForm(2,params,onChangeParams,widthOfChild,isEditing))}
                            {params.ifCondition3 == 1 && (getIfStartForm(3,params,onChangeParams,widthOfChild,isEditing))}
                            {params.ifCondition4 == 1 && (getIfStartForm(4,params,onChangeParams,widthOfChild,isEditing))}
                            {params.ifCondition5 == 1 && (getIfStartForm(5,params,onChangeParams,widthOfChild,isEditing))}

                            
                                
                                
                                
                            </div>
                        </div>
                    </Space>
                )

            

            //-----------------------------------------------
            // pinIfStart
            case 'if-end':
                return (
                    <div style={{borderRadius:10,
                        padding:10,
                        backgroundColor: '#f0f0f0',
                        // outline:  isEditing && '1px solid #ccc',
                        height:isEditing ? 60:60,
                        overflow:"hidden",
                        transition: 'height 0.5s ease',
                        marginLeft:ifWithTab && 50

                        }}>
                        <div style={{float:"left",width:60,height:60,overflow:"hidden"}}>
                            <ImageComponent 
                                src = {emoji_quest_white}
                                width = {60} 
                                height = {60} 
                            />
                        </div>
                        <div style={{float:"left",width:ifWithTab ? (widthOfChild - 90 - 50):(widthOfChild - 90),marginLeft:10,}}>
                            <div style={{fontSize:18,fontWeight:"bold",marginTop:!isEditing && 5}}>条件終了</div>
                            {isEditing ? (
                                <Input value={params.title} onChange={(e)=>{onChangeParams("title",e.target.value)}} />
                            ):(
                                <div style={{color:"#a8a8a8",fontSize:15,marginTop:5}}>{params.title}</div>
                            )}

                            
                            
                            
                        </div>
                    </div>
                )

            // pinNote
            case 'note':
                return (
                    <Space>
                        {ifWithTab && (
                            <TabDiv/>
                        )}
                        <div style={{borderRadius:10,
                            padding:10,
                            backgroundColor: '#f0f0f0',
                            // outline:  isEditing && '1px solid #ccc',
                            height:isEditing ? 120:55,
                            overflow:"hidden",
                            transition: 'height 0.5s ease-in-out',
                            // marginLeft:ifWithTab && 50

                            }}>
                            <div style={{float:"left",width:ifWithTab ? (widthOfChild - 20 - 50):(widthOfChild - 20)}}>
                                {/* <div style={{fontSize:18,fontWeight:"bold",marginTop:!isEditing && 5}}>メモ</div> */}
                                <TextArea
                                    placeholder="メモととる"
                                    autoSize={{
                                    minRows: isEditing ? 5:2,
                                    maxRows: isEditing ? 5:2,
                                    }}
                                    value={params.note}
                                    onChange={(e)=>{onChangeParams("note",e.target.value)}} 
                                />

                                
                                
                                
                            </div>
                        </div>
                    </Space>
                )


            // pin Stop
            case 'stop':
                return (
                    <Space>
                        {ifWithTab && (
                            <TabDiv/>
                        )}
                        <div style={{borderRadius:10,
                            padding:10,
                            backgroundColor: '#f0f0f0',
                            // outline:  isEditing && '1px solid #ccc',
                            height:isEditing ? 60:60,
                            overflow:"hidden",
                            transition: 'height 0.5s ease',
                            // marginLeft:ifWithTab && 50

                            }}>
                            <div style={{float:"left",width:60,height:60,overflow:"hidden"}}>
                                <ImageComponent 
                                    src = {emoji_noentry}
                                    width = {60} 
                                    height = {60} 
                                />
                            </div>
                            <div style={{float:"left",width:ifWithTab ? (widthOfChild - 90 - 50):(widthOfChild - 90),marginLeft:10,}}>
                                <div style={{fontSize:18,fontWeight:"bold",marginTop:!isEditing && 5}}>会話終了</div>
                                {isEditing ? (
                                <Input value={params.title} onChange={(e)=>{onChangeParams("title",e.target.value)}} />
                            ):(
                                <div style={{color:"#a8a8a8",fontSize:15,marginTop:5}}>{params.title}</div>
                            )}  
                            </div>
                        </div>
                    </Space>
                )


            // pinReply
            case 'fixed-output':
                return (
                    <Space>
                        {ifWithTab && (
                            <TabDiv/>
                        )}
                        <div style={{borderRadius:10,
                            padding:10,
                            backgroundColor: '#f0f0f0',
                            // outline:  isEditing && '1px solid #ccc',
                            height:isEditing ? 
                                235
                                :
                                60,
                            overflow:"hidden",
                            transition: 'height 0.5s ease',
                            // marginLeft:ifWithTab && 50

                            }}>
                            <div style={{float:"left",width:60,height:60,overflow:"hidden"}}>
                                <ImageComponent src = {emoji_a} width = {60} height = {60} />
                            </div>
                            <div style={{float:"left",width:ifWithTab ? (widthOfChild - 90 - 50):(widthOfChild - 90),marginLeft:10,}}>
                                <div style={{fontSize:18,fontWeight:"bold",marginTop:!isEditing && 5}}>固定値出力</div>
                                {isEditing ? (
                                <Input value={params.title} onChange={(e)=>{onChangeParams("title",e.target.value)}} />
                            ):(
                                <div style={{color:"#a8a8a8",fontSize:15,marginTop:5}}>{params.title}</div>
                            )}
                            <div style={{height:10}}/>
                            <div style={{width:"100%",marginTop:-5,marginBottom:-20}}><Divider/></div>
                            <div style={{height:10}}/>
                            <div style={{fontSize:15,fontWeight:"bold"}}>内容</div>

                            <div>
                                <div style={{height:10}}/>
                                {/* <div style={{fontSize:15,fontWeight:"bold"}}>固定値</div> */}
                                <TextArea
                                    disabled={!isEditing}
                                    value={params.output}
                                    onChange={(e)=>{onChangeParams("output",e.target.value)}} 
                                    placeholder=""
                                    autoSize={{
                                    minRows: 4,
                                    maxRows: 4,
                                    }}
                                />
                            </div>
                            

                                
                                
                                
                            </div>
                        </div>
                    </Space>
                )



            



















            // api ------------------------------------------
            //----------------------------------------------------------------------------------------------
            //----------------------------------------------------------------------------------------------
            //----------------------------------------------------------------------------------------------
            //----------------------------------------------------------------------------------------------
            // pinAPI

            // rag
            case 'api-rag-normal':
                return (
                    <Space>
                        {ifWithTab && (
                            <TabDiv/>
                        )}
                        <div style={{borderRadius:10,
                            padding:10,
                            backgroundColor: '#f0f0f0',
                            // outline:  isEditing && '1px solid #ccc',
                            height:isEditing ? 60:60,
                            overflow:"hidden",
                            transition: 'height 0.5s ease',
                            // marginLeft:ifWithTab && 50

                            }}>
                            <div style={{float:"left",width:60,height:60,overflow:"hidden"}}>
                                <ImageComponent 
                                    src = {emoji_capricorn}
                                    width = {60} 
                                    height = {60} 
                                />
                            </div>
                            <div style={{float:"left",width:ifWithTab ? (widthOfChild - 90 - 50):(widthOfChild - 90),marginLeft:10,}}>
                                <div style={{fontSize:18,fontWeight:"bold",marginTop:!isEditing && 5}}>RAG</div>
                                {isEditing ? (
                                <Input value={params.title} onChange={(e)=>{onChangeParams("title",e.target.value)}} />
                            ):(
                                <div style={{color:"#a8a8a8",fontSize:15,marginTop:5}}>{params.title}</div>
                            )}

                                
                                
                                
                            </div>
                        </div>
                    </Space>
                )


            // mnemosyne
            case 'api-mnemosyne':
                return (
                    <Space>
                        {ifWithTab && (
                            <TabDiv/>
                        )}
                        <div style={{borderRadius:10,
                            padding:10,
                            backgroundColor: '#f0f0f0',
                            // outline:  isEditing && '1px solid #ccc',
                            height:isEditing ? 60:60,
                            overflow:"hidden",
                            transition: 'height 0.5s ease',
                            // marginLeft:ifWithTab && 50

                            }}>
                            <div style={{float:"left",width:60,height:60,overflow:"hidden"}}>
                                <ImageComponent 
                                    src = {emoji_scorpio}
                                    width = {60} 
                                    height = {60} 
                                />
                            </div>
                            <div style={{float:"left",width:ifWithTab ? (widthOfChild - 90 - 50):(widthOfChild - 90),marginLeft:10,}}>
                                <div style={{fontSize:18,fontWeight:"bold",marginTop:!isEditing && 5}}>Mnemosyne</div>
                                {isEditing ? (
                                <Input value={params.title} onChange={(e)=>{onChangeParams("title",e.target.value)}} />
                            ):(
                                <div style={{color:"#a8a8a8",fontSize:15,marginTop:5}}>{params.title}</div>
                            )}

                                
                                
                                
                            </div>
                        </div>
                    </Space>
                )


            case 'api-search':
                return (
                    <Space>
                        {ifWithTab && (
                            <TabDiv/>
                        )}
                        <div style={{borderRadius:10,
                            padding:10,
                            backgroundColor: '#f0f0f0',
                            // outline:  isEditing && '1px solid #ccc',
                            height:isEditing ? 60:60,
                            overflow:"hidden",
                            transition: 'height 0.5s ease',
                            // marginLeft:ifWithTab && 50

                            }}>
                            <div style={{float:"left",width:60,height:60,overflow:"hidden"}}>
                                <ImageComponent 
                                    src = {emoji_atom}
                                    width = {60} 
                                    height = {60} 
                                />
                            </div>
                            <div style={{float:"left",width:ifWithTab ? (widthOfChild - 90 - 50):(widthOfChild - 90),marginLeft:10,}}>
                                <div style={{fontSize:18,fontWeight:"bold",marginTop:!isEditing && 5}}>Google検索</div>
                                {isEditing ? (
                                <Input value={params.title} onChange={(e)=>{onChangeParams("title",e.target.value)}} />
                            ):(
                                <div style={{color:"#a8a8a8",fontSize:15,marginTop:5}}>{params.title}</div>
                            )}

                                
                                
                                
                            </div>
                        </div>
                    </Space>
                )


            case 'api-mail-send':
                return (
                    <Space>
                        {ifWithTab && (
                            <TabDiv/>
                        )}
                        <div style={{borderRadius:10,
                            padding:10,
                            backgroundColor: '#f0f0f0',
                            // outline:  isEditing && '1px solid #ccc',
                            height:isEditing ? 260:60,
                            overflow:"hidden",
                            transition: 'height 0.5s ease',
                            // marginLeft:ifWithTab && 50

                            }}>
                            <div style={{float:"left",width:60,height:60,overflow:"hidden"}}>
                                <ImageComponent 
                                    src = {emoji_mail}
                                    width = {60} 
                                    height = {60} 
                                />
                            </div>
                            <div style={{float:"left",width:ifWithTab ? (widthOfChild - 90 - 50):(widthOfChild - 90),marginLeft:10,}}>
                                <div style={{fontSize:18,fontWeight:"bold",marginTop:!isEditing && 5}}>メールを送信する</div>
                                {isEditing ? (
                                <Input value={params.title} onChange={(e)=>{onChangeParams("title",e.target.value)}} />
                            ):(
                                <div style={{color:"#a8a8a8",fontSize:15,marginTop:5}}>{params.title}</div>
                            )}
                            <div style={{height:10}}/>
                            <div style={{width:"100%",marginTop:-5,marginBottom:-20}}><Divider/></div>
                            <div style={{height:10}}/>
                            <div style={{fontSize:15,fontWeight:"bold"}}>メールアドレス</div>

                            <Segmented options={["固定",'変数から読み取る']}  
                                block 
                                // defaultValue = {params. == "$USE-ADVANCED-SETTING" ? "❇️ advanced":"🔰 simple"}
                                // onChange={(v)=>{
                                //     console.log(v)
                                //     if(v=="🔰 simple" && this.state.preset_system_current == "$USE-ADVANCED-SETTING"){
                                //         this.setState({
                                //             settingSegment:"simple",
                                //             preset_system_current:"",
                                //             ifChangedRobotSetting:true
                                //         })
                                //     }
                                //     if(v=="❇️ advanced"  && this.state.preset_system_current != "$USE-ADVANCED-SETTING"){
                                //         this.setState({
                                //             settingSegment:"advanced",
                                //             preset_system_current:"$USE-ADVANCED-SETTING",
                                //             ifChangedRobotSetting:true
                                //         })
                                //     }
                                //     this.setState({ifChangedRobotSetting:true})
                                // }}
                            />
                            <div style={{height:10}}/>
                            <Input value={params.email} onChange={(e)=>{onChangeParams("title",e.target.value)}} />
                                
                                
                                
                            </div>
                        </div>
                    </Space>
                )


                    
            // case 'header-h1':
            //     return <div contentEditable={isEditing} onInput={onChangeParams}   style={styles.headerH1}>{note}</div>;
            
            default:
                return null;
        }
    };

    return renderContent();

    
};
export default BlockContent

